@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /* TODO - Test css variables */
    --primary-color: #17789B;
    --secondary-color: #C5161C;
    margin: 0;
    @apply primary-font-family;
    font-size: 16px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.primary-font-family {
    font-family: 'Titillium Web', sans-serif;
}

.secondary-font-family {
    font-family: 'Poppins', sans-serif;
}

/* TODO : Find a better way to do it */
ul:not(.menu):not(.footer):not(.list-content) {
    list-style-type: disc !important;
    padding-left: 15px !important;
}

.hero-banner {
    -webkit-mask-image: radial-gradient(ellipse 60% 10% at bottom, transparent 95%, transparent 20%, #F4F9FA 100%) !important;
}

.page-mobile {
    background: url('../public/assets/svg/triangle_background.svg') repeat-y;
    background-size: 350px;
}

.hashTagText {
    display: inline-block;
    background: #F3D0D2;
    border-radius: 90px;
    color: var(--secondary-color);
    font-weight: 600;
    text-align: justify;
    padding: 10px 16px;
    margin-bottom: 10px;
}

.standard-text {
    @apply secondary-font-family;
    color: #404040;
}

.standard-text-mobile {
    @apply secondary-font-family;
    font-size: 18px;
    font-feature-settings: 'salt' on;
    color: #404040;
}

.header-title {
    font-size: 64px;
    line-height: 110%;
}

.header-title-mobile {
    font-size: 48px;
    line-height: 110%;
}

.header-text {
    @apply secondary-font-family;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #404040;
}

.cardText {
    @apply secondary-font-family;
    font-size: 18px;
    font-feature-settings: 'salt' on;
    color: #404040;
}

.cardDetails {
    @apply secondary-font-family;
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    color: #404040;
}

.mainMenu {
    @apply secondary-font-family;
    position: relative;
    display: inline-block;
    font-feature-settings: 'salt' on;
}

.mainMenu::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
    background: var(--primary-color);
    border: 5px solid #8BBBCD;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.mainMenu:hover::before {
    opacity: 1;
}

.menuSelected::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
    background: var(--primary-color);
    border: 5px solid #8BBBCD;
    border-radius: 50%;
    opacity: 1;
}

.secondaryMenu {
    @apply secondary-font-family;
    position: relative;
    font-size: 14px;
    font-feature-settings: 'salt' on;
}

.secondaryMenu::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    background: var(--primary-color);
    border: 2px solid #8BBBCD;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.secondaryMenu:hover::before {
    opacity: 1;
}

.secondaryMenuSelected::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    background: var(--primary-color);
    border: 2px solid #8BBBCD;
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-out;
}

.copyright {
    @apply secondary-font-family;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: 'salt' on;
    color: #FFFFFF;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.html-display a {
    color: var(--primary-color);
}

.html-display a:hover {
    text-decoration: underline;
}

.custom-shadow {
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
}