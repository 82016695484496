.arrow-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 16px;
}

.arrow-button .arrow-button-label {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    font-feature-settings: 'salt' on;
}