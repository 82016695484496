.rotate {
    animation: rotate 1s linear infinite;
}
@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
    from {
        transform: rotate(0deg);
    }
}