.title-and-subtitle .title {
    font-weight: 600;
}

.title-and-subtitle .title-mobile {
    font-weight: 600;
    display: flex;
    align-items: center;
}

.title-and-subtitle .subtitle {
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
}

.title-and-subtitle .subtitle-mobile {
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    display: flex;
    align-items: center;
}
